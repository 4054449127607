import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.css';
import Intestazione from './Redux/header_menu/headermenu_view'
import LoginView from './Redux/login_page/login_view.js'
import HelpDesk from './Redux/helpdesk/helpdesk_view.js'
import Signup from './Redux/register_page/register_view.js'
import ChangePassword from './Redux/reset_page/reset_view.js'

function Home(){
  return(
    <div className="App">
      <LoginView.LoginPage/>
      <Intestazione.TopMenu />
      <HelpDesk.Application />
    </div>
  )
}

function App() {

  return (
    <Router>
      <Switch>
          
          <Route path="/signup/:token"  component={Signup.Main}></Route>

          <Route path="/reset"  component={ChangePassword.Main}></Route>

          <Route path="/home" component={Home}></Route>

          <Route path="/"><Redirect to="/home"/></Route>
          
        </Switch>
    </Router>
    
  );
}

export default App;
