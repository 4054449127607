import odoo_api from '../../odooAPI/backend_api_wrapper'

export const get_all_mytickets = (id, su_ids, isAdmin) => ({
    type: 'GET_ALL_MYTICKETS',
    payload: odoo_api.get_all_mytickets(id, su_ids, isAdmin)
})

export const get_single_ticket = (id) => ({
    type: 'GET_SINGLE_TICKET',
    payload: odoo_api.get_single_ticket(id)
})

export const get_possible_status_team = (team_id) => ({
    type: 'GET_POSSIBLE_STATUS',
    payload: odoo_api.get_possible_status_team(team_id)
})

export const clear_ticket = () => ({
    type: 'CLEAR_TICKET',
    payload: ''
})

export const clear_new_ticket = () => ({
    type: 'CLEAR_NEWTICKET',
    payload: ''
})

export const get_available_code = (user_id) => ({
    type: 'GET_AVAILABLE_CODE',
    payload: odoo_api.get_available_code(user_id)
})

export const get_team_priority = (team_id) => ({
    type: 'GET_TEAM_PRIORITY',
    payload: odoo_api.get_team_priority(team_id)
})

export const get_team_category = (team_id) => ({
    type: 'GET_TEAM_CATEGORY',
    payload: odoo_api.get_team_category(team_id)
})

export const create_ticket = (name,team_id,tipologia,user_id,type,tagid,company,display_name,email,descrizione,allegati,crapprovata) => {

    var temp_allegati = []

    Object.entries(allegati).map((key, value) => {
        temp_allegati.push({
            name: allegati[key[0]].name,
             base64: allegati[key[0]].base64,
        });
    })

    return {
        type: 'CREATE_TICKET',
        payload: odoo_api.create_ticket(name,team_id,tipologia,user_id,type,tagid,company,display_name,email,descrizione,temp_allegati,crapprovata)
    }
}

export const change_header = (text) => ({
    type: 'CHANGE_HEADER',
    payload: text
})

export const create_chatter_msg = (ticket_id, messaggio, user_id) => ({
    type: 'CREATE_CHATTER',
    payload: odoo_api.create_chatter_msg(ticket_id, messaggio, user_id)
})




