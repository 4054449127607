import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";

const INITIAL_STATE = {
    reset_status : {
        changed: false,
        error: false,
        error_text: 'Login Crendentials Not Valid'
    }
}

const currentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CHECK_TOKEN_VALIDATION':
            return state
        case 'CHECK_TOKEN_VALIDATION_FULFILLED':
            return {
                ...state,
               check_requested: true,
               token_status: action.payload
            }
        case 'RESET_PASSWORD_FULFILLED':
            return {
                ...state,
                reset_status: action.payload
            }  
        default:
            return state
    }
}

export default currentReducer