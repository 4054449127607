import React, {useState} from 'react'
import { Dropdown, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {Button, Tooltip } from 'antd';
import {Link} from "react-router-dom";
import {Image, Input, Message} from 'semantic-ui-react';
import { TryDoLoginOdoo } from "../login_page/login_actions"
 
const CreatePageLogin = ({login, TryDoLoginOdoo}) => {

  function DoLogin(){
    TryDoLoginOdoo(username_value, password_value)
  }

  function EnterDoLogin(e){
    if (e.key === 'Enter') {
      TryDoLoginOdoo(username_value, password_value)
    }
  }

  const [username_value, setusername_value] = useState('');
  const [password_value, setpassword_value] = useState('');

  function SetUsernameValue(e){
    setusername_value(e.target.value)
  }

  function SetPasswordValue(e){
    setpassword_value(e.target.value)
  }

  if(login.info_login.is_loggedin){
        return (<div></div>)
  } else {
       return(
        <div id="login_riquadro">
          <>
          <center>
          <Image id='Logo' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
          </center>
          </>
         
            <>
            <div className='login_class'>
              <Input fluid id='email' placeholder='Email' onChange={SetUsernameValue} onKeyDown={EnterDoLogin}/>
            </div>
          </>
          <>
            <div className='login_class'>
              <Input fluid id='password' type='password' placeholder='Password' onChange={SetPasswordValue} onKeyDown={EnterDoLogin}/>
            </div>
          </>
          <>
            <center>
            <Button disabled={login.info_login.is_loggingin} onClick={DoLogin} id='bottone_login'>Login</Button>
            </center>
          </>
          
          <><center><Link id="link_route" to="/reset">Change Password</Link></center></>
          
          <Message hidden={(!login.info_login.error || login.info_login.is_loggingin ? true : false)} id="santa_pazienza_errore" color='red'>Credenziali non valide!</Message>
          <Message hidden={!login.info_login.is_loggingin} id="santa_pazienza_errore" color='red'>Login In Corso...</Message> 
        </div>
    );
  }
}



const mapStateToProps = state => ({
    login: state.loginPage,
})

const mapDispatchToProps = dispatch => ({
    TryDoLoginOdoo: (username, password) => dispatch(TryDoLoginOdoo(username, password))
  })

export default {
    LoginPage: connect(mapStateToProps, mapDispatchToProps)(CreatePageLogin),
}




