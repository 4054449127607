import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";

const INITIAL_STATE = {
    //stato iniziale dell'oggetto
    sidebar_prop: {
        visible: false,
        animation: 'push',
        direction: 'left',
    },

    user_rights: {
        scheda_commessa_user: false,
        scheda_commessa_admin: true,
        schedulazione_admin: false,
        accounting_tools: false,
        reportistica_admin: false,
    },

    active_menu: {
        label_topheader: 'Selazionare una voce dal Menù',
        scheda_commessa_user: false,
        scheda_commessa_admin: false,
        schedulazione_admin: false,
        accounting_tools: false,
        reportistica_admin: false,
    }
    
}

const currentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'OPEN_SIDE_BAR':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: true
                }
            }
        case 'CLOSE_SIDE_BAR':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                }
            }
        case 'SCHEDA_COMMESSA_USER':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Scheda Commessa - PM',
                    scheda_commessa_user: true,
                    scheda_commessa_admin: false,
                    schedulazione_admin: false,
                    accounting_tools: false,
                    reportistica_admin: false,
                }
            }
        case 'SCHEDA_COMMESSA_ADMIN':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Scheda Commessa - ADMIN',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: true,
                    schedulazione_admin: false,
                    accounting_tools: false,
                    reportistica_admin: false,
                }
            }
        case 'SCHEDULAZIONE':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Schedulazione - PM',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: false,
                    schedulazione_admin: true,
                    accounting_tools: false,
                    reportistica_admin: false,
                }
            }
        case 'ACCOUNTING_TOOLS':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Accounting Tools - PM',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: false,
                    schedulazione_admin: false,
                    accounting_tools: true,
                    reportistica_admin: false,
                }
            }
        case 'REPORTISTICA_ADMIN':
            return {
                ...state,
                sidebar_prop: {
                    ...state.sidebar_prop,
                    visible: false
                },
                active_menu: {
                    ...state.active_menu,
                    label_topheader: 'Reportistica - ADMIN',
                    scheda_commessa_user: false,
                    scheda_commessa_admin: false,
                    schedulazione_admin: false,
                    accounting_tools: false,
                    reportistica_admin: true,
                }
            }
        default:
            return state
    }
}

export default currentReducer