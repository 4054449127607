
import odoo_api from '../../odooAPI/backend_api_wrapper'

export const CheckTokenValidation = (token) => ({
    type: 'CHECK_TOKEN_VALIDATION',
    payload: odoo_api.get_token_info(token)
})

export const ResetPassword = (new_password, old_password, email) => ({
    type: 'RESET_PASSWORD',
    payload: odoo_api.reset_password(new_password, old_password, email)
})
