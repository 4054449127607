import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import promise from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension';



import rootReducer from './global_reducer'

/* const middlewares = [logger, promise] */
const middlewares = [promise]

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(...middlewares),
))

/* const store = createStore(rootReducer, applyMiddleware(...middlewares)); */

export default store;