import moment from 'moment'

const INITIAL_STATE = {
    titolo_header: 'I Miei Tickets',
    ticket_list: [],
    single_ticket_done: false,
    get_possible_status_done: false,
    creating_ticket_done: false,
    loading_creating: false,
    possible_status: null,
    single_ticket: null,
    code: [],
    priorita: [],
    categoria: [],
    chatter: null,
    created_chatter: false,
    numero_ticket_creato: 0,
}

const currentReducer = (state = INITIAL_STATE, action) => {
    console.log('state',state)
    switch (action.type) {

        case 'GET_ALL_MYTICKETS_FULFILLED':

            var ticket_list_new = []

            action.payload.map((ticket) => {
                ticket.close_date = (moment(ticket.close_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? '' : moment(ticket.close_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss'))
                ticket.create_date = (moment(ticket.create_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? '' : moment(ticket.create_date).add(1, 'hours').format('DD/MM/YYYY HH:mm:ss'))      
                ticket_list_new.push(ticket)
            })

            return {
                ...state,
               ticket_list: ticket_list_new
            }
            

        case 'CLEAR_NEWTICKET':
            return {
                ...state,
                categoria: [],
                priorita: []
            }

        case 'GET_SINGLE_TICKET_PENDING':
            return {
                ...state,
                single_ticket_done: false,
                single_ticket: null
            }

        case 'GET_SINGLE_TICKET_FULFILLED':
            return {
                ...state,
                single_ticket_done: true,
                single_ticket: action.payload
            }

        
        case 'GET_POSSIBLE_STATUS_PENDING':
            return {
                ...state,
                get_possible_status_done: false,
                possible_status: null
            }
    
        case 'GET_POSSIBLE_STATUS_FULFILLED':
            return {
                ...state,
                get_possible_status_done: true,
                possible_status: action.payload
            }
        
        case 'CLEAR_TICKET':
            return {
                ...state,
                loading_creating: false,
                single_ticket_done: false,
                get_possible_status_done: false,
                creating_ticket_done: false
                
            }

        case 'GET_AVAILABLE_CODE_FULFILLED':

            var temp_code = []

            action.payload.map((coda, index, array) => {
                temp_code.push({ key: index, text: coda[1], value: coda[0] })
            })

            return {
                ...state,
                code: temp_code
            }
            
        case 'GET_TEAM_PRIORITY_FULFILLED':

            var temp_prior = []

            action.payload.map((prior, index, array) => {
                temp_prior.push({ key: index, text: prior[1], value: prior[0] })
            })

            return {
                ...state,
                priorita: temp_prior
            }

        case 'GET_TEAM_CATEGORY_FULFILLED':

            var temp_categ = []
    
            action.payload.map((categ, index, array) => {
                temp_categ.push({ key: index, text: categ[1], value: categ[0] })
            })
    
            return {
                ...state,
                categoria: temp_categ
            }

        case 'CREATE_TICKET':
            return {
                ...state,
                loading_creating: true,
            }

        case 'CREATE_TICKET_PENDING':
            return {
                ...state,
                loading_creating: true,
            }
            
        case 'CREATE_TICKET_FULFILLED':
            return {
                ...state,
                creating_ticket_done: true,
                numero_ticket_creato: action.payload
            }

        case 'CHANGE_HEADER':
            return {
                ...state,
                titolo_header: action.payload
            }

        case 'CREATE_CHATTER_FULFILLED':
            return {
                ...state,
                created_chatter: true,
                loading_creating: false,
                chatter: action.payload
            }

                    
        default:
            return state
    }
}

export default currentReducer