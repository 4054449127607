import React, {useState} from 'react'
import {Redirect, Link} from "react-router-dom";
import { Dropdown, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {Image, Grid, Message} from 'semantic-ui-react';
import {Badge, Card, Button, Tooltip, Input } from 'antd';
import {ResetPassword} from './reset_actions'

const PasswordPolicy = require('voipex-password-policy')

function ResetPasswordComp ({match, ResetPassword, resetpsw}){

      const [OldPassword, SetOldPassword] = useState('')
      const [Email, SetEmail] = useState('a')
      const [NewPassword, SetNewPassword] = useState('a')
      const [ConfirmNewPassword, SetConfirmNewPassword] = useState('a')
      const [PolicyErrors, SetPolicyErrors] = useState([])
      const [PasswordValid, SetPasswordValid] = useState(false)
      const [PasswordNotequals, SetPasswordNotequals] = useState(false)

      function SaveEmail(e){
        SetEmail(e.target.value)
      }

      function SaveOldPassword(e){
        SetOldPassword(e.target.value)
      }

      function SaveNewPassword(e){
        SetNewPassword(e.target.value)
      }

      function SaveConfirmNewPassword(e){
        SetConfirmNewPassword(e.target.value)
      }


      // Elenco errori generate

      async function Change(){

        const policy = new PasswordPolicy()
        policy.minimumLength = 8
        policy.maximumLength = 14
        policy.minimumNumberOfUpperLetters = 1
        policy.minimumNumberOfDigits = 1

        var ValidatePassword = await policy.validate(NewPassword)

        setTimeout(() => {
          if(ValidatePassword){
            if(ConfirmNewPassword == NewPassword){
              ResetPassword(NewPassword, OldPassword, Email)
            } 
          } else {
            SetPolicyErrors(policy.errors)
          }
        }, 1000)
        

        if(ConfirmNewPassword == NewPassword){
          SetPasswordNotequals(false)
        } else {
          SetPasswordNotequals(true)
        }   


      }

      if(resetpsw.reset_status.changed){
        return(<Redirect to="/home" />)
      }

        return(
          <div id="registrazione_riquadro">

            <div>
                <>
                <center>
                  <Image id='Logo' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
                </center>
                </>
  
                <>
                  <h4 id='titolo_change_pw' class="ui header">Change Password</h4>
                </>
  
                <>
                  <div className='login_class'>
                    <h5 class="ui header">Email</h5>
                    <div class="ui input">
                        <Input onKeyUp={SaveEmail} disabled={false} id='input_registrazione' placeholder={'Email'} type="text"/>
                      </div>
                  </div>
                </>

                <>
                  <div className='login_class'>
                  <h5 class="ui header">Old Password</h5>
                    <div class="ui input">
                        <Input onKeyUp={SaveOldPassword} disabled={false} id='input_registrazione' placeholder={'Old Password'} type="password"/>
                    </div>
                  </div>
                </>
  
                <>
                  <div className='login_class'>
                  <h5 class="ui header">New Password</h5>
                    <div class="ui input">
                      <Tooltip placement="right" title="The password must be at least 8 characters long and must contain at least one capital letter and a number">
                          <Input onKeyUp={SaveNewPassword} disabled={false} id='input_registrazione' placeholder={'New Password'} type="password"/>
                      </Tooltip>
                    </div>
                  </div>
                </>
  
                <>
                  <div className='login_class'>
                  <h5 class="ui header">Confirm New Password</h5>
                    <div class="ui input">
                        <Input onKeyUp={SaveConfirmNewPassword} disabled={false} id='input_registrazione' placeholder={'Confirm New Password'} type="password"/>
                    </div>
                  </div>
                </>

                <Message hidden={!resetpsw.reset_status.error} id="santa_pazienza_errore" color='red'>{resetpsw.reset_status.error_text}</Message>
                

                  <Card id="card_errors_signup" style={{ 'padding-bottom': '10px',  'padding-top': '10px'}} bordered={true} hidden={(PolicyErrors.length == 0 && !PasswordNotequals) ? true : false }>
                    {
                      PolicyErrors.map((Errore) => {
                        if(Errore.validator == 'MinimalLength'){
                          return (<div><Badge status="error" text={'Minimal Length 8 Characters'} /><br></br></div>)
                        }
                        if(Errore.validator == 'minimumNumberOfUpperLetters'){
                          return (<div><Badge status="error" text={'At Least 1 Capital Letter'} /><br></br></div>)
                        }
                        if(Errore.validator == 'minimumNumberOfDigits'){
                          return (<div><Badge status="error" text={'At Least 1 Digit'} /><br></br></div>)
                        }
                        if(Errore.validator == 'MaximalLength'){
                          return (<div><Badge status="error" text={'Maximum Length 14 Characters'} /><br></br></div>)
                        }
                      })
                      
                    }
                    {
                      (PasswordNotequals) ? <div><Badge status="error" text={'Passwords are not Equals'} /><br></br></div> : <div></div>
                    }
                    

                  </Card>

                <>
                  <center>
                    <Button disabled={false} onClick={Change} id='bottone_login_signup'>Change</Button>
                  </center>
                </>
                <br></br>
                  <><center><Link id="link_route" to="/home">Back to HomePage</Link></center></>
                <br></br>
                <br></br>
            </div>

          </div>
      );
           
}

const mapStateToProps = state => ({
  resetpsw: state.resetpsw,
})

const mapDispatchToProps = dispatch => ({
  ResetPassword: (new_password, old_password, email) => dispatch(ResetPassword(new_password, old_password, email)), 
})

export default {
  Main: connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComp),
}









