import odoo_api from '../../odooAPI/backend_api_wrapper'

export const OpenSideBar = () => ({
    type: 'OPEN_SIDE_BAR'
})

export const CloseSideBar = () => ({
    type: 'CLOSE_SIDE_BAR'
})

export const Toogle_SchedaCommessaUser = () => ({
    type: 'SCHEDA_COMMESSA_USER'
})

export const Toogle_SchedaCommessaAdmin = () => ({
    type: 'SCHEDA_COMMESSA_ADMIN'
})

export const Toogle_Schedulazione = () => ({
    type: 'SCHEDULAZIONE'
})

export const Toogle_AccountingTools = () => ({
    type: 'ACCOUNTING_TOOLS'
})

export const Toogle_ReportisticaAdmin = () => ({
    type: 'REPORTISTICA_ADMIN'
})