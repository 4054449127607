
import odoo_api from '../../odooAPI/backend_api_wrapper'

export const CheckTokenValidation = (token) => ({
    type: 'CHECK_TOKEN_VALIDATION',
    payload: odoo_api.get_token_info(token)
})

export const ChangePassword = (user_id, new_password) => ({
    type: 'CHANGE_PASSWORD',
    payload: odoo_api.change_password(user_id, new_password)
})
