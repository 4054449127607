import React from 'react'
import { connect } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu';
import  {IconButton}  from '@material-ui/core';
import {Image, Input, Grid, Message, Button, Dropdown, Header, Checkbox, Menu, Segment, Sidebar, List} from 'semantic-ui-react';
import PersonIcon from '@material-ui/icons/Person';
import { LogoutOdoo } from "../login_page/login_actions"
import { OpenSideBar, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin } from "./headermenu_actions"
 
const CreateHeaderMenu = ({helpDesk, login, header, LogoutOdoo, OpenSideBar, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin}) => {
    if(login.info_login.is_loggedin){
        return (
            <div>
                {(login.info_login.isHelpDeskAdmin ? <div class="corner-ribbon top-right sticky lobra shadow">Admin Mode</div> : <div></div>)}
            <Grid>
            <Grid.Row>
        <Grid.Column width={1}>
           {/*  <IconButton  id='menu' color="secondary" aria-label="add an alarm" onClick={OpenSideBar} ><MenuIcon /></IconButton>
            {GenerateSidebar(header, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin)}*/}      </Grid.Column>
        <Grid.Column width={4}>
        <Image id='Logo_2' src='https://www.lobra.com/wp-content/uploads/2019/09/logo.svg' />
        </Grid.Column>
        <Grid.Column width={7}>
        <> 
        <center>
        <Message id='Titolo' color='red' size='big'>{helpDesk.titolo_header}</Message>
        </center>
        </>
        </Grid.Column>
        <Grid.Column width={4}>
       <Header>
       <Dropdown id='tendina' text={login.info_login.name}>
           <Dropdown.Menu>
               <Dropdown.Item text='Sign Out' onClick={LogoutOdoo}/>
            </Dropdown.Menu>
       </Dropdown>  
       </Header>     
        </Grid.Column>
        </Grid.Row>
        </Grid>
        <br></br>
        <br></br>
        </div>
        )
    } else {
        return(<div></div>)
    }
}

const GenerateSidebar = (header, CloseSideBar, Toogle_SchedaCommessaUser, Toogle_SchedaCommessaAdmin, Toogle_Schedulazione, Toogle_AccountingTools, Toogle_ReportisticaAdmin) => {

    return (
        <div></div>
    )
}

const mapStateToProps = state => ({
    login: state.loginPage,
    header: state.headerMenu,
    helpDesk: state.helpDesk
})

const mapDispatchToProps = dispatch => ({
    LogoutOdoo: () => dispatch(LogoutOdoo()),
    OpenSideBar: () => dispatch(OpenSideBar()),
    CloseSideBar: () => dispatch(CloseSideBar()),
    Toogle_SchedaCommessaUser: () => dispatch(Toogle_SchedaCommessaUser()),
    Toogle_SchedaCommessaAdmin: () => dispatch(Toogle_SchedaCommessaAdmin()),
    Toogle_AccountingTools: () => dispatch(Toogle_AccountingTools()),
    Toogle_Schedulazione: () => dispatch(Toogle_Schedulazione()),
    Toogle_ReportisticaAdmin: () => dispatch(Toogle_ReportisticaAdmin()),
})

export default {
    TopMenu: connect(mapStateToProps, mapDispatchToProps)(CreateHeaderMenu),
}




