import {combineReducers} from 'redux'

import headMenuReducer from '../header_menu/headermenu_reducer'
import LoginPageReducer from '../login_page/login_reducer'
import HelpDeskReducer from '../helpdesk/helpdesk_reducer'
import RegisterReducer from '../register_page/register_reducer'
import ResetPsw from '../reset_page/reset_reducer'

export default combineReducers({
    headerMenu: headMenuReducer,
    loginPage: LoginPageReducer,
    helpDesk: HelpDeskReducer,
    register: RegisterReducer,
    resetpsw: ResetPsw
})