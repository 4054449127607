import moment from 'moment'
import mergeAdvanced from "object-merge-advanced";

const INITIAL_STATE = {
    token_status: {
        isInvalid: true,
    },
    check_requested: false,
    password_changed: false
}

const currentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CHECK_TOKEN_VALIDATION':
            return state
        case 'CHECK_TOKEN_VALIDATION_FULFILLED':
            return {
                ...state,
               check_requested: true,
               token_status: action.payload
            }
        case 'CHANGE_PASSWORD_FULFILLED':
            return {
                ...state,
                password_changed: action.payload
            }  
        default:
            return state
    }
}

export default currentReducer